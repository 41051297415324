.servicio-container {
  padding: 60px;
  @media (max-width: 767px) {
    padding: 60px 20px;
  }
}

.title-servicio {
  color: #fff;
  font-size: 100px;
  padding-left: 30px;
  @media (max-width: 767px) {
    font-size: 65px;
    padding-left: 0;
  }
}

.descrption {
  display: flex;
  flex-direction: column;
  h3 {
    color: #3eafff;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 23px;
  }
  p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    text-align: justify;
  }
  @media (max-width: 767px) {
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
      text-align: left;
    }
  }
}

.contenedor-video {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 25px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  width: 100%;

  .icono {
    margin-right: 10px;
    font-size: 22px;
  }

  .texto {
    flex-grow: 1;
    overflow: hidden;
    font-weight: 300;
    font-size: 22px;
  }
  @media (max-width: 767px) {
    align-items: flex-start;
    .texto {
      font-size: 18px;
    }
  }
}

.bueTitle {
  img {
    width: 15%;
    height: 15%;
    object-fit: contain;
  }
}
.noteboxTitle {
  img {
    width: 30%;
    height: 30%;
    object-fit: contain;
  }
}
.turingTitle {
  img {
    width: 30%;
    height: 30%;
    object-fit: contain;
  }
}

.soeTitle {
  img{
    width: 10%;
    height: 10%;
    object-fit: contain; 
  }
}
.contenedor-img {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.detalle {
  padding: 60px 0 27px 0;
  h3 {
    padding-bottom: 18px;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    text-transform: uppercase;
  }
  p {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    text-align: justify;
  }
}

.line-bottom {
  width: 100%;
  border-bottom: 1px solid #3eafff;
}

.antecedentes {
  width: 100%;
  padding: 40px 0 !important;
  h3 {
    padding-bottom: 18px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
  }
  .antecedentes-datos {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
    padding: 30px 0;
  }
  @media (max-width: 767px) {
    .antecedentes-datos {
      grid-template-columns: 100%; /* Cambia a una sola columna en dispositivos móviles */
      grid-template-rows: auto;
    }
  }
}

.datos {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin: 0 auto;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #3eafff;
  .number {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: rgba(255, 255, 255, 0.6);
      font-size: 34px;
      font-weight: 400;
      line-height: 47px; /* 141.176% */
      letter-spacing: 0.17px;
    }
  }
  .color {
    color: #3eafff;
    font-size: 86px;
    font-weight: 700;
    letter-spacing: 0.43px;
    text-transform: capitalize;
  }
}

.millones-icon {
  width: 75%;
  height: 75%;
  object-fit: contain;
}

.antecedentes-texto {
  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    border-bottom: 6px solid #3eafff;
  }
  .datos-texto {
    padding-top: 20px;
    p {
      font-size: 36px;
      font-weight: 700;
      line-height: 30px;
      padding: 10px 0;
    }
  }
  @media (max-width: 767px) {
    margin-top: 40px;
    h3 {
      font-size: 36px;
      line-height: 46px;
    }
    .datos-texto {
      p {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
}
