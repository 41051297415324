.contacto-container {
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 450px;
  gap: 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; // Cambia a una sola columna en pantallas más pequeñas
  }
}

.image-container {
  .contacto-img {
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
}

.content-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    text-transform: none;
    font-weight: 100;
    line-height: 35px;
  }
  form {
    margin-top: 20px;
    .form-row {
      display: flex;
      margin-bottom: 15px;

      label {
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        padding: 8px 8px 8px 15px;
        border: none;
        border-radius: 5px;
        background: #f1f8fc;
        height: 45px;
        color: #111;
      }

      .half-width-f {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 100%;
        margin-left: 10px;

        input {
          width: calc(100%);
        }
      }

      .half-width {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 100%;

        input {
          width: calc(100%);
        }
        .flex {
          display: flex;
          justify-content: end;
        }
      }
    }

    .btn-container {
      width: 100%;
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
    }

    button {
      background: transparent;
      color: #3eafff;
      border: 1px solid #3eafff;   
      border-radius: 5px;
      padding: 15px 29px;
      cursor: pointer;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.06px;
      text-transform: uppercase;
    }
    button:hover {
      background: #3eafff;
      color: #fff;
      box-shadow: 15px 15px 30px 0px rgba(57, 109, 241, 0.56);
      transition: all 0.2s ease;
    }

    // :last-child {
    //   justify-content: flex-end;
    // }
  }
}

input.invalid {
  color: #eb3d3d !important;
  border: 1px solid #eb3d3d !important;
}
input.invalid::placeholder {
  color: #eb3d3d; /* Color del placeholder para campos inválidos */
}
