.container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 440px; /* Altura del componente */
  //gap: 20px; /* Espacio entre las partes, puedes ajustarlo según tus necesidades */
  padding: 60px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 767px) {
    grid-template-columns: 100%; /* Cambia a una sola columna en dispositivos móviles */
    grid-template-rows: auto; /* Altura automática en dispositivos móviles */
    padding: 60px 20px;
  }
  @media (max-height: 500px) {
    //grid-template-columns: 100%; /* Cambia a una sola columna en dispositivos móviles */
    grid-template-rows: 450px; /* Altura automática en dispositivos móviles */
    padding: 40px 20px;
  }
}

.left-part {
  //background-color: #f0f0f0; /* Color de fondo para la parte izquierda, ajusta según tus preferencias */
  padding: 20px;

  h2 {
    font-size: 52px; /* Tamaño del texto grande, ajusta según tus preferencias */
    margin-bottom: 10px; /* Espaciado inferior del título, ajusta según tus necesidades */
    font-family: "Titillium Web", sans-serif;
    font-weight: 710;
    text-transform: uppercase;

    @media (max-height: 500px) {
      font-size: 50px;
    }
  }

  @media (max-width: 767px) {
    video {
      height: auto; /* Ajusta la altura automáticamente en dispositivos móviles */
    }
  }
}

.right-part {
  //background-color: #e0e0e0; /* Color de fondo para la parte derecha, ajusta según tus preferencias */

  video {
    width: 100%; /* Video ocupa todo el ancho de la parte izquierda */
    height: 100%;
    object-fit: cover; /* Ajusta el tamaño del video manteniendo la proporción */
  }

  @media (max-height: 500px) {
    height: 80%; /* Ajusta la altura automáticamente en dispositivos móviles */
  }
}

.cover-text {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  h3 {
    color: #3eafff;
    text-align: left;
    font-size: 35px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0.175px;
    padding-top: 60px;
  }

  p {
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0.11px;
    padding-top: 40px;
    text-align: justify;
  }

  @media (max-width: 767px) {
    padding: 20px;
    h3 {
      font-size: 21px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 32px;
    }
  }
}

//Partnership section
.component {
  display: flex;
  width: 100%;
  height: 400px;
  margin-top: 70px;
  margin-bottom: 70px;
  padding-left: 8%;
  padding-right: 7%;

  .left-column {
    flex: 1;
    width: 55%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    padding: 20px;

    h2 {
      color: #122332;
      background-color: #fff;
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 85px;
    }

    .logos {
      display: flex;
      background-color: #fff;
      gap: 30px;
      margin: 30px 0;

      img {
        width: 70px;
        max-width: 100%;
        background-color: #fff;
        height: auto;
        object-fit: contain;
      }
    }

    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;

      .left-column,
      .right-column {
        width: 100%;
      }

      .logos {
        flex-wrap: wrap; // Esto permite que las imágenes se envuelvan a una nueva línea en pantallas pequeñas
        justify-content: center; // Centra las imágenes horizontalmente
        gap: 15px; // Espaciado entre las imágenes
        margin: 20px 0; // Espaciado superior e inferior
      }
    }

    p {
      color: #122332;
      background-color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }
  }

  .right-column {
    flex: 1;
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    background: #171717;

    h2 {
      color: #9ce3f4;
      background: #171717;
      font-size: 35px;
      font-weight: 700;
      line-height: 45px;
    }

    .text-logos {
      display: flex;
      background: #171717;
      margin: 30px 0;
      align-items: center;
    }

    .logos {
      display: flex;
      background: #171717;
      gap: 30px;
      margin-bottom: 10px;

      // Adjust the logo styling as needed
      img {
        width: 70px;
        max-width: 100%;
        background: #171717;
        height: auto;
        object-fit: contain;
      }
    }

    p {
      background: #171717;
      width: 60%;
      font-size: 0.9em;
    }
    .sub-text {
      width: 100%;
      p {
        background: #171717;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 35px; /* 140% */
      }
    }
  }

  // Responsive styling
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;

    .left-column,
    .right-column {
      width: 100%;
    }
  }
}
