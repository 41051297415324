.servicios-container {
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  padding: 60px;
  margin: 0 auto;
  animation: fadein 1.5s ease-in-out;

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    padding: 60px 20px;
  }
}
.a {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #001d3f;
  padding: 20px;
  border-radius: 15px;
}
.impacto-container {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
}
.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-right: 1px solid #fff;

  .item {
    display: flex;
    width: 80%;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
    align-items: center;
    gap: 20px;

    .link-bue {
      width: 40%;
      background-color: #001c3f;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      transition: background-color 0.3s ease, background-image 0.3s ease; /* Agregar transición suave */
      text-decoration: none; /* Eliminar subrayado de enlace */
      color: #fff; /* Color del texto */
      object-fit: contain;

      /* Imagen inicial */
      background-image: url("../assets/bue-icon.svg"); /* URL de la imagen inicial */
      padding: 20px;
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
    }

    .link-bue:hover {
      object-fit: contain;
      background-color: #3eafff;
      background-image: url("../assets/download-bue.png"); /* URL de la imagen al hacer hover */
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .link-bue:active {
      transform: translateY(2px); /* Efecto de pulsación */
    }

    .link-turing {
      width: 40%;
      background-color: #001c3f;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      transition: background-color 0.3s ease, background-image 0.3s ease; /* Agregar transición suave */
      text-decoration: none; /* Eliminar subrayado de enlace */
      color: #fff; /* Color del texto */
      object-fit: contain;

      /* Imagen inicial */
      background-image: url("../assets/turing-icon.svg"); /* URL de la imagen inicial */
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }

    .link-turing:hover {
      object-fit: contain;
      background-color: #3eafff;
      background-image: url("../assets/download-turing.png"); /* URL de la imagen al hacer hover */
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .link-turing:active {
      transform: translateY(2px); /* Efecto de pulsación */
    }

    .link-notebox {
      width: 40%;
      background-color: #001c3f;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      transition: background-color 0.3s ease, background-image 0.3s ease; /* Agregar transición suave */
      text-decoration: none; /* Eliminar subrayado de enlace */
      color: #fff; /* Color del texto */
      object-fit: contain;

      /* Imagen inicial */
      background-image: url("../assets/notebox.svg"); /* URL de la imagen inicial */
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
    }

    .link-notebox:hover {
      object-fit: contain;
      background-color: #3eafff;
      background-image: url("../assets/download-notebox.png"); /* URL de la imagen al hacer hover */
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .link-notebox:active {
      transform: translateY(2px); /* Efecto de pulsación */
    }
    .link-soe {
      width: 40%;
      background-color: #001c3f;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      transition: background-color 0.3s ease, background-image 0.3s ease; /* Agregar transición suave */
      text-decoration: none; /* Eliminar subrayado de enlace */
      color: #fff; /* Color del texto */
      object-fit: contain;

      /* Imagen inicial */
      background-image: url("../assets/soe-icon.svg"); /* URL de la imagen inicial */
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
    }

    .link-soe:hover {
      object-fit: contain;
      background-color: #3eafff;
      background-image: url("../assets/download-soe.png"); /* URL de la imagen al hacer hover */
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .link-soe:active {
      transform: translateY(2px); /* Efecto de pulsación */
    }

    .text {
      width: 60%;
    }

    .text h4 {
      font-size: 24px;
      font-weight: bold;
      line-height: 38px; /* 158.333% */
      letter-spacing: 0.12px;
      text-transform: uppercase;
      font-family: "Titillium Web", sans-serif;
    }

    .text span {
      color: #3eafff;
      font-size: 18px;
      font-weight: 700;
      line-height: 34px; /* 188.889% */
      letter-spacing: 0.09px;
    }
  }
  @media (max-width: 767px) {
    border-right: none;
    .item {
      width: 100%;
    }
  }
}

.data {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  h3 {
    font-size: 65px;
    font-weight: 700;
    padding-bottom: 20px;
    font-family: "Titillium Web", sans-serif;
  }
  p {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px; /* 125% */
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .number {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    align-items: flex-start;
    span {
      font-size: 100px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      font-family: "Titillium Web", sans-serif;
    }
    .uppercase {
      font-size: 45px;
      font-weight: bold;
      line-height: 40px; /* 160% */
      letter-spacing: 0.125px;
      color: #fff;
    }
    .lowercase {
      font-size: 25px;
      font-weight: bold;
      line-height: 40px; /* 160% */
      letter-spacing: 0.125px;
      color: #fff;
      text-transform: lowercase;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    h3 {
      font-size: 50px;
      line-height: 60px;
    }
    p {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

.links {
  margin-top: 25px;
  gap: 40px;
  display: flex;
  .link {
    color: #fff;
    text-align: center;
    padding: 10px 16px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.075px;
    text-transform: uppercase;
    border-radius: 5px;
    background: #3eafff;
  }
  .transparent {
    background: transparent;
    color: #3eafff;
    border: 1px solid #3eafff;
  }
  .transparent:hover {
      background: #3eafff;
      color: #fff;
  }
}

.datos-impacto {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin: 0 auto;
  justify-content: center;
  padding: 46px;
  border-bottom: 1px solid #3eafff;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 50px;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-size: 65px;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 42px;
    line-height: 60px;
  }
}
.number {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 34px;
    font-weight: 400;
    line-height: 32px; /* 141.176% */
    letter-spacing: 0.17px;
    text-transform: uppercase;
    text-align: center;
  }
}
.color {
  color: #3eafff;
  font-size: 86px;
  font-weight: bold;
  letter-spacing: 0.43px;
  text-transform: capitalize;
  font-family: "Titillium Web", sans-serif;
}

.servicios-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #001d3f;
  padding: 20px;
  border-radius: 15px;
}
