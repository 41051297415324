// Header.scss

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px;
  background: #001530;
  color: #fff;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-height: 500px) {
    height: 100px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 20px;

    .logo-container {
      margin-bottom: 30px;
      h3 {
        padding-bottom: 20px;
      }
    }
  }

  .lang-responsive {
    .lang-selected {
      color: #fff;
      padding: 3px;
      border-radius: 4px;
      font-weight: bold;
    }
    .none-selected {
      background-color: transparent;
      color: #75757587;
      padding: 3px;
      font-weight: bold;
    }
  }

  .hide {
    display: none;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    img {
      width: 180px;
      height: auto;
    }
    .logo-suite {
      width: 300px;
      height: auto;
      margin-bottom: 10px;
    }
  }

  .button-container {
    button {
      background: transparent;
      color: #3eafff;
      border: 1px solid #3eafff;   
      border-radius: 5px;
      padding: 15px 29px;
      cursor: pointer;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.06px;
      text-transform: uppercase;
    }
    :hover {
      background: #3eafff;
      color: #fff;
      box-shadow: 15px 15px 30px 0px rgba(57, 109, 241, 0.56);
      transition: all 0.2s ease;
    }
  }
}

.right-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
