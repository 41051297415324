.footer-container {
  width: 100%;
  padding: 40px 0;
  animation: fadein 1.5s ease-in-out;

  .container-footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan en pantallas más pequeñas */
    justify-content: space-between;
  }

  .column-f {
    flex: 1;
    padding: 10px;

    .icon {
      img {
        width: 18px;
        height: 18px;
      }
    }

    p {
      display: flex;
      align-items: center; /* Alinear verticalmente el contenido */
      gap: 10px;
    }
  }

  .title-column {
    flex: 1.5;
    h2 {
      font-size: 16px;
      padding-bottom: 8px;
    }
    p {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    .column-f {
      flex: 1 0 100%; /* Cambiar a una columna completa en móvil */
      padding: 10px 0; /* Ajustar el espacio entre columnas en pantallas más pequeñas */
    }
  }
}
.footer-container {
  width: 100%;
  padding: 40px 0;

  .container-footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan en pantallas más pequeñas */
    justify-content: space-between;
  }

  .column-f {
    flex: 1;
    padding: 10px;

    .icon {
      img {
        width: 18px;
        height: 18px;
      }
    }

    p {
      display: flex;
      align-items: center; /* Alinear verticalmente el contenido */
      gap: 10px;
    }
  }

  .title-column {
    flex: 1.5;
    h2 {
      font-size: 16px;
      padding-bottom: 8px;
    }
    p {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    .column-f {
      flex: 1 0 100%; /* Cambiar a una columna completa en móvil */
      padding: 10px 0; /* Ajustar el espacio entre columnas en pantallas más pequeñas */
    }
  }
}
