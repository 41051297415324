$color-primary: #E60000;
$color-background: #012f57;
$color-title: #050505;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    background: #001530;
    font-family: 'Open Sans', sans-serif;
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  .general-button {
    cursor: pointer;
    border-radius: 8px;
    padding: 16px 36px 15px 36px;
    background-color: $color-primary;
    color: $color-background;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  h1{
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: center;
  }

  h2{
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
  }

  p {
    font-size: 16px;
  }


  button {
    cursor: pointer;
    border: none;
  }

  .flex-container{
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    @media (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
  }
  }
  
  .row{
    flex-direction: row;
  }
  
  .column{
    flex-direction: column;
  }

  .enpa-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 24%);
    margin-top: 30px;
    column-gap: 1%;
  }

  .go-back{
    display: flex;
    flex-direction: row;
    align-items: center;
    p{
      padding: 0 0 0 5px;
    };
  }

  .tab-container{
    width: 100%;
    .tab-text{
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0 auto;
    }
  }

  .datos-container{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    height: auto;
    margin: 0 auto;
    .box-data{
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      span{
        font-size: 2.5rem;
        font-weight: 700;
      }
      p{
        text-align: center;
      }
    }
  }

  @media (min-width: 800px) and (max-width: 1050px) {
    .grid-responsive{
      grid-template-columns: repeat(3, 32%);

    }
   }

  @media (min-width: 299px) and (max-width: 799px) {
    h2{
      font-size: 32px;
    }

    .grid-responsive{
      grid-template-columns: 100%; 
    }
  }
  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .section {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .section.animate {
    opacity: 1;
  }
  
  